import './cod.css';

import React, { useState, useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import {
    Table,
    Typography,
    Select,
    Space,
    Button,
    Form,
    Input,
    DatePicker,
    AutoComplete,
} from 'antd';

import dayjs from 'dayjs';

import {
    SaveOutlined,
    CaretDownOutlined,
    EditOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import BreadCrumbs from "../../modules/breadCrumbs";

import { dataInit } from './table/data';
import { addLog, addLogIdle, getListCod, getListCodClear, getListCodIdle, searchFilter, searchFilterIdle } from '../../modules/cod/actions';
import { codSelector, searchFilterDataSelector } from '../../modules/cod/selector';

import ExportForm from './components/export/export'
import { fieldsLogs, fieldsShipment } from '../../constants/config';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { TextArea } = Input;

const EditableColumn = ({props}) => {
    
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    const [value, setValue] = useState('');

    //console.log('test');

    const count = useSelector(state => state.cod.count);
    const statusLog = useSelector(state => state.cod.addLogStatus);
    const initData = useSelector(state => state.initData);
    const email = useSelector(state => state.authApp.init.email);

    useEffect(() => {
        setValue(props._);
        setCurrentValue(props._);
    }, [props._])

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };
    
    return (
        <>
            {!edit && (
                <>{value}</>
            ) || (
                <>
                    {props.key == 'Comment' && (
                        <TextArea
                            value={value}
                            style={{ width: '100%'}}
                            placeholder="Комментарий"
                            onChange={(e) => {
                                setValue(e.target.value);
                            }} 
                        />
                    ) || (
                        <DatePicker 
                            value={value && dayjs(value, 'DD.MM.YYYY HH:mm') || ''}
                            // value={value}
                            style={{ width: '100%' }} 
                            locale={locale}
                            placeholder="Дата"
                            format={'DD.MM.YYYY HH:mm'}
                            minuteStep={1}
                            disabledDate={props.key == 'ActualArrivalDateTime' && disabledDate || false}
                            showTime={{
                              format: 'HH:mm',
                            }}
                            onChange={(e, dateString) => {
                                // console.log(dayjs(e))
                                setValue(dateString ? dateString : '')
                            }}
                        />
                    )}
                    <Space.Compact block style={{ marginTop: 10 }}>
                        <Button onClick={e => {
                            setEdit(false);
                            setCurrentValue(value);

                            if(statusLog == 'IDLE'){

                                // console.log(props.data, props.record);

                                let indexElement = props.data.findIndex(item => item.ShipmentId == props.record.ShipmentId && item.Location == props.record.Location);

                                let newData = props.data;

                                let shipmentProps = {
                                    [fieldsShipment.PROPERTY_725.code]: newData[indexElement].ActualArrivalDateTime && newData[indexElement].ActualArrivalDateTime.split(' ')[0] || '',
                                    [fieldsShipment.PROPERTY_726.code]: newData[indexElement].ActualArrivalDateTime && newData[indexElement].ActualArrivalDateTime.split(' ')[1] || '',
                                    [fieldsShipment.PROPERTY_727.code]: newData[indexElement].ActualDepartureDateTime && newData[indexElement].ActualDepartureDateTime.split(' ')[0] || '',
                                    [fieldsShipment.PROPERTY_728.code]: newData[indexElement].ActualDepartureDateTime && newData[indexElement].ActualDepartureDateTime.split(' ')[1] || '',
                                    [fieldsShipment.PROPERTY_729.code]: newData[indexElement].Comment,
                                }
                                if(props.key == 'Comment'){
                                    shipmentProps[fieldsShipment.PROPERTY_729.code] = value;
                                    newData[indexElement] = {
                                        ...newData[indexElement],
                                        Comment: value
                                    }
                                }
                                if(props.key == 'ActualArrivalDateTime'){
                                    shipmentProps[fieldsShipment.PROPERTY_725.code] = value.split(' ')[0];
                                    shipmentProps[fieldsShipment.PROPERTY_726.code] = value.split(' ')[1];    
                                    newData[indexElement] = {
                                        ...newData[indexElement],
                                        ActualArrivalDateTime: value
                                    }                          
                                }
                                if(props.key == 'ActualDepartureDateTime'){
                                    shipmentProps[fieldsShipment.PROPERTY_727.code] = value.split(' ')[0];
                                    shipmentProps[fieldsShipment.PROPERTY_728.code] = value.split(' ')[1]; 
                                    newData[indexElement] = {
                                        ...newData[indexElement],
                                        ActualDepartureDateTime: value
                                    }                                 
                                }
                                // console.log(newData, shipmentProps);
                                props.setData(newData);
                                // console.log(props.record);
                                
                                dispatch(addLog({
                                    shipmentPropsAll: props.record,
                                    shipmentProps: {
                                        'bxDeliveryId': props.record.bxDeliveryId,
                                        ...shipmentProps,
                                    },
                                    logProps: {
                                        'ELEMENT_CODE': props.record.ShipmentId + '_' + props.record.Location,
                                        'FIELDS': {
                                            //номер склада + _ + номер рейса
                                            NAME: props.record.Wh + '_' + props.record.ShipmentId,
                                            [fieldsLogs.PROPERTY_721.code]: props.record.ShipmentId,
                                            [fieldsLogs.PROPERTY_722.code]: email && email || '',
                                            [fieldsLogs.PROPERTY_742.code]: email && email || '',
                                            [fieldsLogs.PROPERTY_723.code]: dayjs().format('DD.MM.YYYY'),
                                            [fieldsLogs.PROPERTY_724.code]: dayjs().format('HH:mm'),
                                        }
                                    }
                                }))
                            }

                        }}>
                            <SaveOutlined />
                        </Button>
                        <Button onClick={e => {
                            setEdit(false);
                            setValue(currentValue);
                        }}>
                            <CloseCircleOutlined />
                        </Button>
                    </Space.Compact>
                </>
            )}
            {!edit && (
                <Space.Compact block>
                    <Button onClick={e => setEdit(!edit)}>
                        <EditOutlined />
                    </Button>
                </Space.Compact>
            )}
        </>
    )
}

const SearchInput = (props) => {
    
    const [data, setData] = useState(props.options);
    // const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    
    const status = useSelector(state => state.cod.searchFilterStatus);
    // const searchFilterData = useSelector(searchFilterDataSelector);

    // useEffect(() => {
    //     if(status == 'SUCCESS'){
    //         // setOptions(searchFilterData);
    //         dispatch(searchFilterIdle());
    //     }
    // }, [status])

    let timeoutSearch = null;

    return (
        <Select
            mode="multiple"
            allowClear
            // defaultValue={[]}
            // showSearch
            filterOption={false}
            value={props.value}
            placeholder={props.placeholder}
            style={{ width: '100%', minWidth: 100, marginBottom: 10 }} 
            onSearch={(e, z) => {
                // console.log(e, z)
                // console.log(props.options.filter(item => item.label.indexOf(e) >= 0));
                let filtersData = props.options.filter(item => item.label.indexOf(e) >= 0);
                setData(filtersData);
            }}
            onChange={(e, z) => {
                // console.log(z);
                props.setSelectedKeys(e ? e : [])
                // setValue(e);
            }}
            // onSearch={e => {
            //     clearTimeout(timeoutSearch);
            //     timeoutSearch = setTimeout(function(){
            //         if(status == 'IDLE'){
            //             dispatch(searchFilter({
            //                 value: e,
            //                 listId: props.listId
            //             }))
            //         }
            //     }, 1000);
            // }}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.label,
            }))}
            // options={props.options}
        />
    );
  };

const Cod = () => {

    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState(dataInit);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [view, setView] = useState('cod');
    const [filtersCurrent, setFiltersCurrent] = useState({
        limit: 10,
        offset: 0,
        "InWork": "Y"
    })

    const [showCount, setShowCount] = useState(50);
    const [valueShowCount, setValueShowCount] = useState(50);
    const [start, setStart] = useState(0);

    const dispatch = useDispatch();

    const { status, count } = useSelector(state => state.cod);
    const statusLog = useSelector(state => state.cod.addLogStatus);
    const initData = useSelector(state => state.initData);
    const dateFormat = 'DD.MM.YYYY HH:mm';
    const dateFormatShort = 'DD.MM.YYYY';
    
    useEffect(() => {
        if(statusLog == 'SUCCESS'){

            setData(result);
            dispatch(addLogIdle());
        }
    }, [statusLog])

    const [breadcrumb, setBreadcrumb] = useState([
        { name: 'Главная', link: `/` },
        { name: 'Сервисы', link: `/` },
        { name: 'Confirmation of Delivery', link: `/cod` }
    ]);

    const changeShowCount = (value) => {
        setValueShowCount(value);

        if(status == 'IDLE'){
            dispatch(getListCod({
                pageSize: value
            }));
        }

    };
    let result = useSelector(codSelector);

    console.log(result);

    useEffect(() => {
        if(status == 'IDLE'){
            dispatch(getListCod({
                filters: {
                    limit: 10,
                    offset: 0,
                    "InWork": "Y"
                }
            }));
        }
    }, [])



    useEffect(() => {
        if(status == 'SUCCESS'){
            setData(result);
            dispatch(getListCodIdle());
        }
    }, [status])

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };

    const disabledRangeTime = (_, type) => {
      if (type === 'start') {
        return {
          disabledMinutes: () => range(30, 60),
        };
      }
      return {
        disabledMinutes: () => range(0, 31),
      };
    };

    // console.log(initData);

    const getColumnSearchProps = (dataIndex, placeholder, listId, type) => ({
        className: 'cod-table__col',
        // filteredValue: filtersData[dataIndex] && filtersData[dataIndex] || null,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            // console.log(selectedKeys);
            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    {dataIndex == 'ActualArrivalDateTime' && (
                        <Select
                            ref={searchInput} 
                            style={{ marginBottom: 10, width: 200 }} 
                            placeholder="Заполнено поле COD"
                            onChange={e => {
                                setSelectedKeys(e ? e : '')
                            }}
                            options={[
                                { value: 'Y', label: 'Да' },
                                { value: 'N', label: 'Нет' },
                            ]}
                        />
                    ) || dataIndex == 'LddDate' && (
                        <RangePicker 
                            style={{ marginBottom: 10, width: '100%' }} 
                            ref={searchInput} 
                            locale={locale}
                            placeholder={placeholder}
                            format={dateFormatShort}
                            onChange={(e, dateString) => {
                                setSelectedKeys(dateString ? dateString : '')
                            }}
                        />
                        // <DatePicker 
                        //     // value={selectedKeys[0]}
                        //     ref={searchInput} 
                        //     style={{ marginBottom: 10, width: '100%' }} 
                        //     locale={locale}
                        //     placeholder={placeholder}
                        //     format={dateFormatShort}
                        //     // minuteStep={30}
                        //     // showTime={{
                        //     //   format: 'HH:mm',
                        //     // }}
                        //     onChange={(e, dateString) => {
                        //         // console.log(dayjs(e))
                        //         setSelectedKeys(dateString ? dateString : '')
                        //     }}
                        // />
                    ) || ( dataIndex == 'ShipmentId') && (
                        <Input
                            ref={searchInput}
                            placeholder={placeholder}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            style={{ marginBottom: 10, width: '100%' }} 
                            allowClear
                        />
                    ) || (type && type == 'string' ) && (
                        <Input
                            ref={searchInput}
                            placeholder={placeholder}
                            value={selectedKeys}
                            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : '')}
                            style={{ marginBottom: 10, width: '100%' }} 
                            allowClear
                        />
                    ) || (
                        <SearchInput
                            setSelectedKeys={setSelectedKeys}
                            value={selectedKeys}
                            listId={listId}
                            ref={searchInput} 
                            placeholder={placeholder}
                            style={{ marginBottom: 10 }} 
                            options={initData.warehouses && Object.values(initData.warehouses).length > 0 && Object.values(initData.warehouses).map(item => {
                                return {
                                    value: item.ID,
                                    label: item.NAME
                                }
                            }) || []}
                        />
                    )}
                    <Button 
                        type="primary" 
                        onClick={() => {
                            confirm({ closeDropdown: true });
                        }}
                    >
                        Применить
                    </Button>
                </div>
            )
        },
        filterIcon: (filtered) => (
            <CaretDownOutlined style={{ color: '#ffffff' }} />
        )
    });

    const allParams = {
        className: 'cod-table__col',
    }
    
    const columns = [
        {
            title: 'Shipment ID',
            dataIndex: 'ShipmentId',
            fixed: 'left',
            ...allParams,
            ...getColumnSearchProps('ShipmentId', 'Shipment ID', 71, null, 'Shipment')
        },{
            title: 'Source location',
            dataIndex: 'SourceLocation',
            fixed: 'left',
            ...allParams,
            ...getColumnSearchProps('SourceLocation', 'Source location', 33, null, 'Wh')
        },{
            title: 'LDD Date, LDD Time',
            dataIndex: 'LddDateTime',
            ...allParams,
            ...getColumnSearchProps('LddDate', 'LDD Date', null, null, 'LDD date')
        },{
            title: 'Stop Num',
            dataIndex: 'StopNumber',
            ...allParams,
            // ...getColumnSearchProps('stop_num', 'Stop Num')
        },{
            title: 'Location',
            dataIndex: 'Location',
            ...allParams,
            // ...getColumnSearchProps('location', 'Location')
        },{
            title: 'Location Name',
            dataIndex: 'LocationName',
            ...allParams,
            ...getColumnSearchProps('LocationName', 'Location', null, 'string')
            // ...getColumnSearchProps('location_name', 'Location Name')
        },{
            title: 'City',
            dataIndex: 'LocationCity',
            ...allParams,
            ...getColumnSearchProps('City', 'City', null, 'string')
            // ...getColumnSearchProps('city', 'City')
        },{
            title: 'Province',
            dataIndex: 'LocationObl',
            ...allParams,
            ...getColumnSearchProps('Province', 'Province', null, 'string')
            // ...getColumnSearchProps('province', 'Province')
        },{
            title: 'Customer Address',
            dataIndex: 'LocationAddress',
            ...allParams,
            ...getColumnSearchProps('CustomerAddress', 'Customer Address', null, 'string')
            // ...getColumnSearchProps('customer_address', 'Customer Address')
        },{
            title: 'COD',
            dataIndex: 'ActualArrivalDateTime',
            width: 300,
            render: (_, record) => {
                return <EditableColumn props={{ _, record, key: "ActualArrivalDateTime", data, setData }} />
            },
            ...allParams,
            ...getColumnSearchProps('ActualArrivalDateTime', 'COD')
        },{
            title: 'Actual Departure',
            dataIndex: 'ActualDepartureDateTime',
            width: 300,
            render: (_, record) => {
                return <EditableColumn props={{ _, record, key: "ActualDepartureDateTime", data, setData }} />
            },
            ...allParams,
            // ...getColumnSearchProps('actual_departure', 'Actual Departure')
        },{
            title: 'Remark',
            dataIndex: 'Comment',
            width: 300,
            render: (_, record) => {
                return <EditableColumn props={{ _, record, key: "Comment", data, setData }} />
            },
            ...allParams,
            // ...getColumnSearchProps('remark', 'Remark')
        },{
            title: 'ФИО водителя',
            dataIndex: 'DriverName',
            ...allParams,
            ...getColumnSearchProps('DriverName', 'Driver Name', null, 'string')
            // ...getColumnSearchProps('remark', 'Код перевозчика по рейсу')
        },{
            title: 'Код перевозчика по рейсу',
            dataIndex: 'CarrierCode',
            ...allParams,
            ...getColumnSearchProps('CarrierCode', 'Carrier Code', null, 'string')
            // ...getColumnSearchProps('remark', 'Код перевозчика по рейсу')
        },{
            title: 'Наименование перевозчика',
            dataIndex: 'TransportTitle',
            ...allParams,
            ...getColumnSearchProps('CarrierName', 'Carrier Name', null, 'string')
            // ...getColumnSearchProps('remark', 'Код перевозчика по рейсу')
        },{
            title: 'Номер авто',
            dataIndex: 'CarNumber',
            ...allParams,
            ...getColumnSearchProps('CarNumber', 'Car Number', null, 'string')
            // ...getColumnSearchProps('remark', 'Код перевозчика по рейсу')
        },{
            title: 'Email пользователя',
            dataIndex: 'Email',
            ...allParams,
            // ...getColumnSearchProps('remark', 'Email пользователя')
        },{
            title: 'Последние изменения',
            dataIndex: 'LastUpdateDateTime',
            ...allParams,
            // ...getColumnSearchProps('last_modified', 'Последние изменения')
        },{
            title: 'Стоимость рейса',
            dataIndex: 'Cost',
            ...allParams,
            /// ...getColumnSearchProps('price_shipment', 'Стоимость рейса')
        },
    ];

    const handleTableChange = (pagination, filters) => {
        // console.log(filters);
        if(status == 'IDLE'){
            let filtersNow = {
                limit: 10,
                offset: 0,
                "InWork": "Y"
            }
            if(filters.LddDateTime && filters.LddDateTime[0]){
                filtersNow['LddDateFrom'] = filters.LddDateTime[0];
            }
            if(filters.LddDateTime && filters.LddDateTime[1]){
                filtersNow["LddDateTo"] = filters.LddDateTime[1];
            }
            if(filters.ActualArrivalDateTime){
                filtersNow["cod"] = filters.ActualArrivalDateTime;
            }
            if(filters.SourceLocation){
                filtersNow["WarehouseId"] = filters.SourceLocation;
            }
            if(filters.ShipmentId){
                filtersNow["ShipmentNo"] = filters.ShipmentId;
            }
            if(filters.LocationName){
                filtersNow["LocationName"] = filters.LocationName;
            }
            if(filters.LocationObl){
                filtersNow["Province"] = filters.LocationObl;
            }
            if(filters.LocationCity){
                filtersNow["City"] = filters.LocationCity;
            }
            if(filters.LocationAddress){
                filtersNow["CustomerAddress"] = filters.LocationAddress;
            }
            if(filters.DriverName){
                filtersNow["DriverName"] = filters.DriverName;
            }
            if(filters.CarrierCode){
                filtersNow["CarrierCode"] = filters.CarrierCode;
            }
            if(filters.TransportTitle){
                filtersNow["CarrierName"] = filters.TransportTitle;
            }
            if(filters.CarNumber){
                filtersNow["CarNumber"] = filters.CarNumber;
            }
            setFiltersCurrent(filtersNow);
            dispatch(getListCodClear());
            dispatch(getListCod({
                filters: filtersNow
            }));
        }
    };

    const handleResetFilters = () => {
        // setFiltersData({});
        document.querySelectorAll('.ant-select-selection-search-input').forEach(item => {
            item.value = '';
        })
        if(status == 'IDLE'){
            dispatch(getListCod({
                filters: null
            }));
        }
    }

    return (
        <main className="main">
            <div className="main__content container">
                <div className="main__wrap">

                    <BreadCrumbs breadcrumb={breadcrumb} />     

                    {view == 'export' && (
                        <a onClick={e => {
                            e.preventDefault();

                            setView('cod');

                        }} className="btn" href="#">Вернуться</a>
                    ) || (
                            <a onClick={e => {
                                e.preventDefault();

                                setView('export');

                            }} className="btn" href="#">Выгрузить в Excel</a>
                        )}       

                </div>

                <div className='cod'>

                    <Title>Confirmation of Delivery</Title>

                    {view == 'export' && <ExportForm setView={setView} />}

                    {view !== 'export' && (
                        <div className='cod-table'>

                            <div className='cod-table__count-show'>
                                <Space wrap size={24}>
                                    {/* <Select
                                        size='large'
                                        defaultValue={valueShowCount}
                                        onChange={changeShowCount}
                                        options={[
                                            { value: 50, label: <>Показывать по: <b>50</b></> },
                                            // { value: 2, label: <>Показывать по: <b>2</b></> },
                                            // { value: 3, label: <>Показывать по: <b>3</b></> },
                                            // { value: 4, label: <>Показывать по: <b>4</b></> },
                                        ]}
                                        suffixIcon={<CaretDownOutlined style={{ color: '#1F36C7' }} />}
                                    /> */}
                                    {/* <Button 
                                        size='large'
                                        type="primary" 
                                        style={{
                                            background: '#1F36C7',
                                            borderRadius: 0,
                                            fontSize: 14,
                                            fontWeight: 700
                                        }}
                                        onClick={e => setShowCount(valueShowCount)}
                                    >Применить</Button> */}
                                    {/* {filtersData && Object.values(filtersData) && Object.values(filtersData).length > 0 && (
                                        <Button 
                                            size='large'
                                            type="primary" 
                                            style={{
                                                background: '#1F36C7',
                                                borderRadius: 0,
                                                fontSize: 14,
                                                fontWeight: 700
                                            }}
                                            onClick={handleResetFilters}
                                        >Сбросить</Button>
                                    )} */}
                                </Space>
                            </div>

                        
                            <Table 
                                loading={status == 'LOADING' && true || false}
                                columns={columns} 
                                dataSource={data} 
                                rowClassName={'cod-table__row'}
                                pagination={false}
                                onChange={handleTableChange}
                                scroll={{ x: 3200, y: 500 }}
                            />

                            {count > data.length && (
                                <div style={{ textAlign: 'center', marginTop: 20 }}>
                                    <a onClick={e => {
                                        e.preventDefault();

                                        let startCurrent = start + 50;

                                        dispatch(getListCod({
                                            filters: {
                                                ...filtersCurrent,
                                                limit: 10,
                                                offset: data.length
                                            },
                                        }))

                                        setStart(startCurrent);

                                    }} className="btn" href="#">Подгрузить еще</a>
                                </div>
                            )}

                        </div>
                    )}

                </div>
            </div>
        </main>
    )
}

export default Cod;